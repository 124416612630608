import React from "react";

const Store = React.createContext();

export const useStore = () => React.useContext(Store);

export const StoreProvider = ({ children, reducer, initialState }) => {
  const [globalState, dispatch] = React.useReducer(reducer, initialState);

  const changeOfInput = async (event) => {
    console.log("In change of input", event.target.value);
    const receivedData = await globalState.functionResult(event.target.value);
    console.log("Received data:", receivedData);
    const { changeOfInput, receiveSuccess, response } = receivedData;
    const [searchInput, valueList] = changeOfInput;
    dispatch({ type: "SET VALUE", searchInput });
    dispatch({ type: "SET VALUELIST", valueList });
    if (!receiveSuccess) {
      dispatch({ type: "SET ERROR DATA", errorData: response });
    }
  };

  const OnSelectChange = (event, data) => {
    console.log(event?.target?.value, data);
    if (data !== null) {
      dispatch({ type: "SET SELVALUE", selValue: data });
    } else {
      dispatch({ type: "SET VALUE", searchInput: "" });
    }
  };

  return (
    <Store.Provider
      value={{ globalState, dispatch, changeOfInput, OnSelectChange }}
    >
      {children}
    </Store.Provider>
  );
};

import { useState } from "react";
// import { Blurhash } from "react-blurhash";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import styled from "styled-components";

const useImageLoad = () => {
  const [isLoaded, setLoaded] = useState(false);
  const [isLoadStarted, setLoadStarted] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  const handleLoadStarted = () => {
    setLoadStarted(true);
  };

  return {
    handleLoad,
    handleLoadStarted,
    isLoaded,
    isLoadStarted,
  };
  // <ImageWrapper>
  //     <LazyLoadImage
  //       key={image.name}
  //       src={url}
  //       height={500}
  //       width={333}
  //       onLoad={handleLoad}
  //       beforeLoad={handleLoadStarted}
  //     />
  //     {!isLoaded && isLoadStarted && (
  //       <StyledBlurhash
  //         hash={image.blurhash}
  //         width={333}
  //         height={500}
  //         resolutionX={32}
  //         resolutionY={32}
  //         punch={1}
  //       />
  //     )}
  //   </ImageWrapper>
};

export default useImageLoad;

import { useRef, useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Blurhash } from "react-blurhash";

import LoginImage from "../images/login_module/LoginGraphic.svg";
import useImageLoad from "../hooks/use-imageLoad";
import LoginInputTextboxField from "../components/to_use_hooks/LoginInputTextboxField";
import Button from "../loginModuleComponents/Button";
import TitleElement from "../loginModuleComponents/TitleElement";
import { isNotEmpty, otpCharValidate } from "../utilities/validationFunction";
import "../scss/login_module.scss";
import "../scss/set2FA.scss";

import { ReactComponent as CopyIcon } from "../images/profileDetails/copyIcon.svg";
import { ApiHandler } from "../api/ApiHandler";
import FloatingNotifications from "../components/FloatingNotifications";

const Reset2FA = () => {
  let history = useHistory();
  const location = useLocation();
  const [valid, setValid] = useState(false);
  const [twoFA, setTwoFA] = useState("");
  const [loginData, setLoginData] = useState({});
  const { handleLoad, handleLoadStarted, isLoaded, isLoadStarted } =
    useImageLoad();

  const storeData = useCallback((fieldName, value, validityStatus) => {
    console.log(fieldName, value, validityStatus);
    setInputData((prevState) => {
      // console.log(prevState);
      return { ...prevState, [fieldName]: value };
    });
    if (inputDataValidity.hasOwnProperty(fieldName)) {
      setInputDataValidity((prevState) => ({
        ...prevState,
        [fieldName]: validityStatus,
      }));
    }
  }, []);

  const error_message = {
    enterOtp: "This field should not be empty",
    totp: "This field should not be empty",
  };

  const resetRefs = {
    enterOtp: useRef(null),
    totp: useRef(null),
  };

  const [inputData, setInputData] = useState({
    enterOtp: "",
    totp: "",
  });

  const [inputDataValidity, setInputDataValidity] = useState({
    enterOtp: false,
    totp: false,
  });
  const totpClickHandler = async (event) => {
    event.preventDefault();
    Object.values(resetRefs).forEach((value) => {
      value.current.submitClick();
    });
    console.log("reset link button clicked", inputData);
    if (valid) {
      const res = await ApiHandler({
        requestConfig: {
          method: "post",
          endPoint: "set2FA",
          data: {
            otp: inputData.enterOtp,
            totp: inputData.totp,
            username: loginData.username,
          },
        },
      });

      console.log(res);
      if (res.hasOwnProperty("beforeFunc")) {
        if (res.beforeFunc.type === "HISTORY PUSH") {
          history.push(res.beforeFunc.value);
        }
      }

      if (res.status === "success") {
        const { data } = res;
        console.log(data);
        history.push("login");
        FloatingNotifications("success", "Two FA validated successfully", 1500);
      } else {
        if (res.errorMsg !== "") {
          FloatingNotifications("error", res.errorMsg, 1500);
        }
      }
    }
  };
  useEffect(() => {
    var temp = true;
    Object.values(inputDataValidity).forEach((value) => {
      temp = temp && value;
    });
    setValid(temp);
  }, [inputDataValidity]);

  const getTwoFA = async (loginData) => {
    const res = await ApiHandler({
      requestConfig: {
        method: "post",
        endPoint: "forgot2fa",
        data: {
          ...loginData,
        },
      },
    });

    console.log(res);
    if (res.hasOwnProperty("beforeFunc")) {
      if (res.beforeFunc.type === "HISTORY PUSH") {
        history.push(res.beforeFunc.value);
      }
    }

    if (res.status === "success") {
      const { data } = res;
      console.log(data);
      setTwoFA(data.twoFA);
    } else {
      if (res.errorMsg !== "") {
        FloatingNotifications("error", res.errorMsg, 1500);
      }
    }
  };

  useEffect(() => {
    console.log(location.state);
    if (location.state ?? false) {
      console.log("Have data");
      if (location.state?.loginData ?? false) {
        if (twoFA === "") {
          getTwoFA(location.state?.loginData);
        }
        console.log("loginData = ", location.state?.loginData);
        setLoginData(location.state?.loginData);
      } else {
        history.push("/login");
        return;
      }
    }
  }, [location.state]);
  const onCopyClick = () => {
    navigator.clipboard.writeText(twoFA);
  };
  return (
    <div className="login_module">
      <div className="login_left">
        <div className="resetLink">
          <div className="resetLink1">
            <TitleElement />
            <form className="mainBlock">
              <div className="title">Reset 2FA</div>
              <div className="desc">
                Copy the belw two FA in Google Authenticator App and enter the
                totp below
              </div>
              <div className="twoFAContainer">
                <div className="twoFaTitle">Two FA</div>
                <div className="element">
                  <div className="text">{twoFA}</div>

                  <div className="copyIcon" onClick={onCopyClick}>
                    <CopyIcon />
                  </div>
                </div>
              </div>
              <LoginInputTextboxField
                ref={resetRefs["enterOtp"]}
                title="Enter OTP"
                validation={isNotEmpty}
                charValidation={otpCharValidate}
                error_message={error_message["enterOtp"]}
                storeData={storeData.bind(null, "enterOtp")}
                superText=""
                autoComplete="new-password"
              />
              <LoginInputTextboxField
                ref={resetRefs["totp"]}
                title="TOTP"
                type="password"
                validation={isNotEmpty}
                charValidation={otpCharValidate}
                error_message={error_message["totp"]}
                storeData={storeData.bind(null, "totp")}
                superText=""
                autoComplete="new-password"
              />

              <Button
                divClName="totpButton"
                buttonClName="totp"
                onClick={totpClickHandler}
                BtnTxt="Reset 2FA"
              />
            </form>
          </div>
        </div>
      </div>
      <div className="login_right">
        <div>
          {!isLoaded && isLoadStarted && (
            <Blurhash
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                margin: "0",
              }}
              hash={
                "|51#jXZxQkkDkYaHaHf.j?f9e+e,fle,kZf,e-flR2kupMa#bJe,kFflf5g7f.f:f6kEabadflj^e,aHf,e,kEkZacaxf4gSf-f-fmaHflkDf,f5g6fnj]f-abade,f5f,f.f5e+f-j?f5fkacfjflf4e*f5fjf5f-flac"
              }
              resolutionX={32}
              resolutionY={32}
              punch={0}
            />
          )}
          <LazyLoadImage
            style={{
              height: "100%",
              width: "100%",
            }}
            src={LoginImage}
            // effect="blur"
            onLoad={handleLoad}
            beforeLoad={handleLoadStarted}
          />
        </div>
      </div>
    </div>
  );
};

export default Reset2FA;

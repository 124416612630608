import Design1 from "../images/login_module/design1.png";

const TitleElement = () => {
  return (
    <div className="companyContent">
      <div className="designElement">
        <img src={Design1} alt="" />
      </div>
      <div>Marina LIS</div>
    </div>
  );
};
export default TitleElement;

import { useRef, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Blurhash } from "react-blurhash";

import LoginInputTextboxField from "../components/to_use_hooks/LoginInputTextboxField";
import TitleElement from "../loginModuleComponents/TitleElement";
import { isNotEmpty } from "../utilities/validationFunction";
import Button from "../loginModuleComponents/Button";
import LoginImage from "../images/login_module/LoginGraphic.svg";
import ProfileImage from "../images/lockScreen_page/profile.png";

import { ApiHandler } from "../api/ApiHandler";

import FloatingNotifications from "../components/FloatingNotifications";
import useImageLoad from "../hooks/use-imageLoad";

import "../scss/login_module.scss";
import "../scss/lockScreen.scss";

const LockScreen = () => {
  const username = localStorage.getItem("marinaUser");
  let history = useHistory();
  const [showMismatch, setShowMismatch] = useState(false);
  const { handleLoad, handleLoadStarted, isLoaded, isLoadStarted } =
    useImageLoad();
  const storeData = useCallback((value, validityStatus) => {
    console.log(value, validityStatus);
    setInputData(value);
    setInputDataValidity(validityStatus);
  }, []);

  const error_message = "Password should not be empty";
  const resetRefs = useRef(null);

  const [inputData, setInputData] = useState("");

  const [inputDataValidity, setInputDataValidity] = useState(false);

  useEffect(() => {
    if (showMismatch) {
      setShowMismatch(false);
    }
  }, [inputData]);

  const continueClickHandler = async (event) => {
    event.preventDefault();
    resetRefs.current.submitClick();

    if (inputDataValidity) {
      const res = await ApiHandler({
        requestConfig: {
          method: "post",
          endPoint: "lockScreen",
          data: { password: inputData },
        },
        headers: {
          authorization: `Bearer ${localStorage.getItem("marinaRefToken")}`,
        },
      });
      console.log(res);
      if (res.hasOwnProperty("beforeFunc")) {
        if (res.beforeFunc.type === "HISTORY PUSH") {
          history.push(res.beforeFunc.value);
        }
      }
      if (res.status === "success") {
        const { accessToken, refreshToken } = res.data;
        console.log(res.data);
        localStorage.setItem("marinaToken", accessToken);
        localStorage.setItem("marinaRefToken", refreshToken);
        history.push(`/${localStorage.getItem("marinaLoginType")}`);
        console.log("twoFA match");
      } else {
        setShowMismatch(true);
        if (res.errorMsg !== "") {
          FloatingNotifications("error", res.errorMsg, 1500);
        }
      }
    }
  };

  return (
    <>
      <div className="login_module">
        <div className="login_left">
          <div className="lockScreen">
            <div className="lockScreen1">
              <TitleElement />
              <form className="mainBlock">
                <div className="profileImg">
                  <img src={ProfileImage} alt="" />
                </div>
                <div className="name">Hi ! {username}</div>
                <div className="desc">Enter your password for access</div>
                <LoginInputTextboxField
                  ref={resetRefs}
                  title="Password"
                  type="password"
                  validation={isNotEmpty}
                  error_message={error_message}
                  storeData={storeData}
                  superText=""
                  autoComplete="new-password"
                />
                <Button
                  divClName="loginButton"
                  buttonClName="login"
                  onClick={continueClickHandler}
                  BtnTxt="Login"
                />
                {showMismatch && (
                  <div className="misMatch">
                    Mismatch of username and Password
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="login_right">
          <div>
            {!isLoaded && isLoadStarted && (
              <Blurhash
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  margin: "0",
                }}
                hash={
                  "|51#jXZxQkkDkYaHaHf.j?f9e+e,fle,kZf,e-flR2kupMa#bJe,kFflf5g7f.f:f6kEabadflj^e,aHf,e,kEkZacaxf4gSf-f-fmaHflkDf,f5g6fnj]f-abade,f5f,f.f5e+f-j?f5fkacfjflf4e*f5fjf5f-flac"
                }
                resolutionX={32}
                resolutionY={32}
                punch={0}
              />
            )}
            <LazyLoadImage
              style={{
                height: "100%",
                width: "100%",
              }}
              src={LoginImage}
              // effect="blur"
              onLoad={handleLoad}
              beforeLoad={handleLoadStarted}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default LockScreen;

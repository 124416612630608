import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import LoginContainer from "./components/LoginContainer";
import DefaultContainer from "./components/DefaultContainer";
import ErrorPage from "./components/ErrorPage";
import { StoreProvider } from "./contexts/StoreContext";
import { initialState, storeReducer } from "./reduce/StoreReduce";
import { NotificationContainer } from "react-notifications";

import "./scss/utility.scss";
function App() {
  // window.location.reload(false);
  return (
    <>
      <div>
        <BrowserRouter>
          <StoreProvider initialState={initialState} reducer={storeReducer}>
            <Switch>
              <Route path="/:accessType(lab|org|subOrg)">
                <DefaultContainer />
              </Route>
              <Route path="/" component={LoginContainer} />
              <Route path="*" component={ErrorPage} />
            </Switch>
          </StoreProvider>
        </BrowserRouter>
      </div>
      <NotificationContainer />
    </>
  );
}

export default App;

import { useRef, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Blurhash } from "react-blurhash";

import LoginImage from "../images/login_module/LoginGraphic.svg";
import useImageLoad from "../hooks/use-imageLoad";
import LoginInputTextboxField from "../components/to_use_hooks/LoginInputTextboxField";
import TitleElement from "../loginModuleComponents/TitleElement";
import Button from "../loginModuleComponents/Button";
import { emailValidation } from "../utilities/validationFunction";

import { ApiHandler } from "../api/ApiHandler";
import FloatingNotifications from "../components/FloatingNotifications";

import "../scss/login_module.scss";
import "../scss/resetPassword.scss";

const ResetPassword = () => {
  let history = useHistory();
  const [showMismatch, setShowMismatch] = useState(false);
  const { handleLoad, handleLoadStarted, isLoaded, isLoadStarted } =
    useImageLoad();
  const storeData = useCallback((value, validityStatus) => {
    console.log(value, validityStatus);
    setInputData(value);
    setInputDataValidity(validityStatus);
  }, []);

  const error_message = "Please enter a valid Email";
  const resetRefs = useRef(null);

  const [inputData, setInputData] = useState("");

  const [inputDataValidity, setInputDataValidity] = useState(false);

  useEffect(() => {
    if (showMismatch) {
      setShowMismatch(false);
    }
  }, [inputData]);

  const continueClickHandler = async (event) => {
    event.preventDefault();
    resetRefs.current.submitClick();

    if (inputDataValidity) {
      const res = await ApiHandler({
        requestConfig: {
          method: "post",
          endPoint: "forgotPassword",
          data: { email: inputData },
        },
      });

      console.log(res);
      if (res.hasOwnProperty("beforeFunc")) {
        if (res.beforeFunc.type === "HISTORY PUSH") {
          history.push(res.beforeFunc.value);
        }
      }

      if (res.status === "success") {
        const { data } = res;
        console.log(data);
        FloatingNotifications("success", "Email sent successfully", 1500);
        history.push("resetPasswordSuccessLink", { email: inputData });
      } else {
        if (res.errorMsg !== "") {
          FloatingNotifications("error", res.errorMsg, 1500);
        }
      }
    }
  };

  return (
    <div className="login_module">
      <div className="login_left">
        <div className="resetPassword">
          <div className="resetPassword1">
            <TitleElement />
            <form className="mainBlock">
              <div className="title">Reset Password</div>
              <div className="desc">
                Enter your email address and we’ll send you an email with
                instructions to reset your password
              </div>
              <LoginInputTextboxField
                ref={resetRefs}
                title="Email"
                validation={emailValidation}
                error_message={error_message}
                storeData={storeData}
                superText=""
                autoComplete="new-password"
              />

              <Button
                divClName="continueButton"
                buttonClName="continue"
                onClick={continueClickHandler}
                BtnTxt="Continue"
              />
              {showMismatch && <div className="misMatch">Invalid Email</div>}
            </form>
          </div>
        </div>
      </div>
      <div className="login_right">
        <div>
          {!isLoaded && isLoadStarted && (
            <Blurhash
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                margin: "0",
              }}
              hash={
                "|51#jXZxQkkDkYaHaHf.j?f9e+e,fle,kZf,e-flR2kupMa#bJe,kFflf5g7f.f:f6kEabadflj^e,aHf,e,kEkZacaxf4gSf-f-fmaHflkDf,f5g6fnj]f-abade,f5f,f.f5e+f-j?f5fkacfjflf4e*f5fjf5f-flac"
              }
              resolutionX={32}
              resolutionY={32}
              punch={0}
            />
          )}
          <LazyLoadImage
            style={{
              height: "100%",
              width: "100%",
            }}
            src={LoginImage}
            // effect="blur"
            onLoad={handleLoad}
            beforeLoad={handleLoadStarted}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import { useReducer } from "react";

const initialInputState = {
  value: "",
  submitClicked: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return { value: action.value, submitClicked: false };
  }

  if (action.type === "RESET") {
    return { value: "", submitClicked: false };
  }
  if (action.type === "SUBMIT") {
    return { value: state.value, submitClicked: true };
  }
  return initialInputState;
};

const useLoginInputTextbox = (
  validateValue,
  validateChar = (char, value) => true
) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const valueIsValid = validateValue(inputState.value);
  const hasError = !valueIsValid && inputState.submitClicked;

  const valueChangeHandler = (event) => {
    if (validateChar(event.nativeEvent.data, event.target.value)) {
      dispatch({ type: "INPUT", value: event.target.value });
    }
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  const submitClicked = () => {
    dispatch({ type: "SUBMIT" });
  };
  return {
    value: inputState.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    reset,
    submitClicked,
  };
};

export default useLoginInputTextbox;

import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

const FloatingNotifications = (type, message, time) => {
  // const { type, message } = data;
  console.log("received notification ", type, message);
  if (type === "info") {
    NotificationManager.info(message ?? "Info message", "Info", 10000);
  } else if (type === "success") {
    console.log("Inside success condition block");
    NotificationManager.success(
      message ?? "Success message",
      "Success",
      time ?? 5000,
      () => {},
      true
    );
  } else if (type === "warning") {
    NotificationManager.warning(
      message ?? "Warning message",
      "Warning",
      time ?? 3000
    );
  } else if (type === "error") {
    NotificationManager.error(
      message ?? "Error message",
      "Error",
      time ?? 2000
    );
  }
};
export default FloatingNotifications;

import { Route, Switch, Redirect } from "react-router-dom";

import Login from "../pages/Login";
import TwoFA from "../pages/TwoFA";
import LockScreen from "../pages/LockScreen";
import ResetPassword from "../pages/ResetPassword";
import ResetPasswordSuccess from "../pages/ResetPasswordSuccess";
import ResetPasswordSuccessLink from "../pages/ResetPasswordSuccessLink";
import Set2FA from "../pages/Set2FA";
import ResetLink from "../pages/ResetLink";
import Reset2FA from "./../pages/Reset2FA";
import ErrorPage from "./ErrorPage";

const LoginContainer = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/login" />
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/twoFA" exact>
        <TwoFA />
      </Route>
      <Route path="/lockScreen" exact>
        <LockScreen />
      </Route>
      <Route path="/resetPassword" exact>
        <ResetPassword />
      </Route>
      <Route path="/resetPasswordSuccess" exact>
        <ResetPasswordSuccess />
      </Route>
      <Route path="/resetLink" exact>
        <ResetLink />
      </Route>
      <Route path="/resetPasswordSuccessLink" exact>
        <ResetPasswordSuccessLink />
      </Route>
      <Route path="/set2FA" exact>
        <Set2FA />
      </Route>
      <Route path="/reset2FA" exact>
        <Reset2FA />
      </Route>
      <Route path="*" component={ErrorPage} />
    </Switch>
  );
};

export default LoginContainer;

import axios from "axios";
// const baseURL = "https://marinalis.herokuapp.com/api";
// const baseURL = "http://localhost:3001/api";

const routes = {
  twoFA: "/auth/2fa",
  login: "/auth/login",
  lockScreen: "/auth/refresh",
  addLogin: "/auth/register",
  forgotPassword: "/auth/forgetpassword",
  resetPassword: "/auth/resetpassword",
  set2FA: "/auth/set2fa",
  forgot2fa: "/auth/forgot2fa",
  allocateKitNo: "/order/kitno",
  createdOrderList: "/order/getorder",
  createNewOrder: "/order/neworder",
  getKitAdded: "/order/collection",
  changeOrderStatusToCollected: "/order/collected",
  getScanRfid: "/order/scanrfid",
  getCollectedOrders: "/order/received",
  changeOrderStatusToReceived: "/order/updatereceived",
  crelioUpdateData: "/order/crelioapi",
  getallorder: "/order/getallorder",
  deleteOrder: "/order/delete",
  updateTestData: "/order/updatetestdata",
  getTestList: "/kit/gettest",
  getKitList: "/kit/getkit",
  createKit: "/kit/createkit",
  getPanelList: "/kit/getpanel",
  createTest: "/kit/createtest",
  createPanel: "/kit/createpanel",
  getPatientListBySearch: "/form/patient",
  getIndiPatientDetails: "/form/patientdetail",
  addModPatientDetails: "/form/newpatient",
  getLoginList: "/users/loginlist",
  dashboardOrders: "/dashboard/getallorder",
  dashboardTests: "/dashboard/getalltest",
  getProfileList: "profile/list",
  addSubOrg: "/profile/suborg",
  addOrg: "/profile/org",
  getProfileDetails: "/profile/details",
  getLoginDetails: "/profile/logindetails",
  updateLoginDetails: "/profile/updatedetails",
  changePassword: "/profile/changepassword",
  reset2FA: "/profile/change2fa",
};
axios.defaults.baseURL = "https://marinalis.herokuapp.com/api";

// const ApiInstance = axios.create({
//   baseURL,
//   // headers: {
//   //   authorization: `Bearer ${localStorage.getItem("marinaToken")}`,
//   // },
//   // timeout: 1000,
// });

export const ApiHandler = async ({ requestConfig, headers }) => {
  let response,
    resultData = {};

  // console.log(`Bearer ${localStorage.getItem("marinaToken")}`);
  // console.log(headers);
  if (requestConfig === undefined) {
    console.log("Error requestConfig not given");
    return { status: "error", errorMsg: "requestConfig is not provided" };
  }

  let { method, data, endPoint } = requestConfig;

  console.log(method, data, endPoint);
  if (endPoint === undefined) {
    console.log("Error endpoint not given");
    return { status: "error", errorMsg: "endpoint is not provided" };
  }
  if (!Object.keys(routes).includes(endPoint)) {
    console.log("Error endpoint absent");
    return { status: "error", errorMsg: `Invalid endpoint ${endPoint}` };
  }
  if (method === undefined) {
    console.log("Error method not given");
    return { status: "error", errorMsg: "method is not provided" };
  }

  if (!["get", "post", "put"].includes(method)) {
    console.log("Error invalid method");
    return { status: "error", errorMsg: "Invalid method" };
  }

  try {
    if (method === "get") {
      response = await axios.get(routes[endPoint], {
        params: data,
        headers: headers ?? {
          authorization: `Bearer ${localStorage.getItem("marinaToken")}`,
        },
      });
    } else if (method === "post") {
      response = await axios.post(routes[endPoint], data, {
        headers: headers ?? {
          authorization: `Bearer ${localStorage.getItem("marinaToken")}`,
        },
      });
    } else if (method === "put") {
      response = await axios.put(routes[endPoint], data, {
        headers: headers ?? {
          authorization: `Bearer ${localStorage.getItem("marinaToken")}`,
        },
      });
    }
  } catch (err) {
    console.log("Some error while API call ", err);
    response = err.response;
  }
  console.log(response);
  const { status, data: resData, statusText } = response;

  if (status === 200) {
    resultData = { status: "success", data: resData };
  } else if (status >= 400 && status < 500) {
    if (status === 400) {
      if (resData.statuscode === 1001) {
        resultData = {
          status: "error",
          errorMsg: `${resData.error[0]} Missing`,
        };
      } else if (resData.statuscode === 1002) {
        resultData = {
          status: "error",
          errorMsg: resData.error,
        };
      } else if (resData.statuscode === 1003) {
        resultData = {
          status: "error",
          errorMsg: resData.error,
        };
      } else {
        resultData = {
          status: "error",
          errorMsg: statusText,
        };
      }
    } else if (status === 401) {
      resultData = {
        status: "error",
        errorMsg: "",
        beforeFunc: { type: "HISTORY PUSH", value: "/login" },
      };
    } else if (status === 403) {
      resultData = {
        status: "error",
        errorMsg: "",
        beforeFunc: { type: "HISTORY PUSH", value: "/lockScreen" },
      };
    } else if (status === 404) {
      resultData = {
        status: "error",
        errorMsg: "Page not found",
      };
    }
  } else if (status >= 500) {
    resultData = { status: "error", errorMsg: `Error ${status}` };
  } else if (status === 0) {
    resultData = { status: "error", errorMsg: "Network timed out" };
  }

  console.log(response, resultData);
  console.log("Gone through everything");
  return resultData;
};

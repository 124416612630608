import { useRef, useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Blurhash } from "react-blurhash";

import LoginInputTextboxField from "../components/to_use_hooks/LoginInputTextboxField";
import TitleElement from "../loginModuleComponents/TitleElement";
import { isNotEmpty } from "../utilities/validationFunction";
import Button from "../loginModuleComponents/Button";
import PasswordImage from "../images/twoFA_page/Password.png";
import TwoFaImage from "../images/twoFA_page/TwoFaGraphic.svg";
import { ApiHandler } from "../api/ApiHandler";

import { NotificationContainer } from "react-notifications";
import FloatingNotifications from "../components/FloatingNotifications";
import useImageLoad from "../hooks/use-imageLoad";

import "../scss/login_module.scss";
import "../scss/twoFA.scss";

const TwoFA = () => {
  let history = useHistory();
  const location = useLocation();
  const [showMismatch, setShowMismatch] = useState(false);
  const [loginData, setLoginData] = useState({});
  const { handleLoad, handleLoadStarted, isLoaded, isLoadStarted } =
    useImageLoad();
  const storeData = useCallback((value, validityStatus) => {
    console.log(value, validityStatus);
    setInputData(value);
    setInputDataValidity(validityStatus);
  }, []);

  const error_message = "TwoFA should not be empty";
  const resetRefs = useRef(null);

  const [inputData, setInputData] = useState("");

  const [inputDataValidity, setInputDataValidity] = useState(false);

  useEffect(() => {
    if (showMismatch) {
      setShowMismatch(false);
    }
  }, [inputData]);

  const continueClickHandler = async (event) => {
    event.preventDefault();
    resetRefs.current.submitClick();

    if (inputDataValidity) {
      console.log({ ...loginData, twoFA: inputData });
      const res = await ApiHandler({
        requestConfig: {
          method: "post",
          endPoint: "twoFA",
          data: { ...loginData, twoFA: inputData },
        },
      });
      console.log(res);
      if (res.hasOwnProperty("beforeFunc")) {
        if (res.beforeFunc.type === "HISTORY PUSH") {
          history.push(res.beforeFunc.value);
        }
      }
      if (res.status === "success") {
        const {
          accessToken,
          refreshToken,
          loginType,
          userType,
          name,
          id,
          loginBelow,
          loginBelowName,
        } = res.data;
        console.log(res.data);
        localStorage.setItem("marinaToken", accessToken);
        localStorage.setItem("marinaRefToken", refreshToken);
        localStorage.setItem("marinaUser", name);
        localStorage.setItem("marinaLoginType", loginType);
        localStorage.setItem("marinaUserType", userType);
        localStorage.setItem("marinaUserId", id);
        localStorage.setItem("marinaOrgId", loginBelow);
        localStorage.setItem("marinaOrgName", loginBelowName);
        history.push(loginType);
        console.log("twoFA match");
      } else {
        setShowMismatch(true);
        FloatingNotifications("error", res.errorMsg, 1500);
      }
    }
  };
  const forgotTwoFAClick = () => {
    history.push("/reset2FA", { loginData });
  };
  useEffect(() => {
    if (location.state ?? false) {
      setLoginData(location.state?.loginData ?? {});
      console.log("loginData = ", location.state?.loginData ?? "");
    }
  }, [location.state]);
  return (
    <>
      <div className="login_module">
        <div className="login_left">
          <div className="twoFA">
            <div className="twoFA1">
              <TitleElement />
              <form className="mainBlock">
                <div className="passwordImg">
                  <img src={PasswordImage} alt="" />
                </div>
                <LoginInputTextboxField
                  ref={resetRefs}
                  title="Enter 2fa from Authenticator App"
                  validation={isNotEmpty}
                  error_message={error_message}
                  storeData={storeData}
                  superText=""
                  autoComplete="new-password"
                  placeholder=""
                  autoFocus={true}
                />
                <div className="forgotTwoFASection">
                  {/* <div className="learnMore">Learn More</div> */}
                  <div className="forgotTwoFA" onClick={forgotTwoFAClick}>
                    Forgot 2FA
                  </div>
                </div>
                <Button
                  divClName="continueButton"
                  buttonClName="continue"
                  onClick={continueClickHandler}
                  BtnTxt="Continue"
                />
                {showMismatch && <div className="misMatch">Wrong Two FA</div>}
              </form>
            </div>
          </div>
        </div>
        <div className="login_right">
          <div>
            {!isLoaded && isLoadStarted && (
              <Blurhash
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  margin: "0",
                }}
                hash={
                  "|51#jXZxQkkDkYaHaHf.j?f9e+e,fle,kZf,e-flR2kupMa#bJe,kFflf5g7f.f:f6kEabadflj^e,aHf,e,kEkZacaxf4gSf-f-fmaHflkDf,f5g6fnj]f-abade,f5f,f.f5e+f-j?f5fkacfjflf4e*f5fjf5f-flac"
                }
                resolutionX={32}
                resolutionY={32}
                punch={0}
              />
            )}
            <LazyLoadImage
              style={{
                height: "100%",
                width: "100%",
              }}
              src={TwoFaImage}
              // effect="blur"
              onLoad={handleLoad}
              beforeLoad={handleLoadStarted}
            />
          </div>
        </div>
      </div>
      <NotificationContainer />;
    </>
  );
};

export default TwoFA;

import { useRef, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Blurhash } from "react-blurhash";

import LoginInputTextboxField from "../components/to_use_hooks/LoginInputTextboxField";
import Button from "../loginModuleComponents/Button";
import TitleElement from "../loginModuleComponents/TitleElement";
import { isNotEmpty } from "../utilities/validationFunction";
import LoginImage from "../images/login_module/LoginGraphic.svg";
import "../scss/login_module.scss";
import "../scss/login.scss";
import { ApiHandler } from "../api/ApiHandler";
import FloatingNotifications from "../components/FloatingNotifications";
import useImageLoad from "../hooks/use-imageLoad";

const Login = () => {
  let history = useHistory();
  const [valid, setValid] = useState(false);
  const [showMismatch, setShowMismatch] = useState(false);
  const [misMatchText, setMisMatchText] = useState("");
  const { handleLoad, handleLoadStarted, isLoaded, isLoadStarted } =
    useImageLoad();
  const storeData = useCallback((fieldName, value, validityStatus) => {
    // console.log(fieldName, value, validityStatus);
    setInputData((prevState) => {
      // console.log(prevState);
      return { ...prevState, [fieldName]: value };
    });
    if (inputDataValidity.hasOwnProperty(fieldName)) {
      setInputDataValidity((prevState) => ({
        ...prevState,
        [fieldName]: validityStatus,
      }));
    }
  }, []);

  const error_message = {
    username: "User name should not be empty",
    password: "Password should not be empty",
  };

  const resetRefs = {
    username: useRef(null),
    password: useRef(null),
  };

  const [inputData, setInputData] = useState({
    username: "",
    password: "",
  });

  const [inputDataValidity, setInputDataValidity] = useState({
    username: false,
    password: false,
  });

  useEffect(() => {
    var temp = true;
    Object.values(inputDataValidity).forEach((value) => {
      temp = temp && value;
    });
    setValid(temp);
    if (showMismatch) {
      setShowMismatch(false);
    }
  }, [inputDataValidity]);

  const submitClickHandler = async (event) => {
    console.log("reached here");
    event.preventDefault();
    Object.values(resetRefs).forEach((value) => {
      value.current.submitClick();
    });
    // console.log(inputData);
    if (valid) {
      try {
        const res = await ApiHandler({
          requestConfig: {
            method: "post",
            endPoint: "login",
            data: inputData,
          },
        });

        console.log(res);
        if (res.hasOwnProperty("beforeFunc")) {
          if (res.beforeFunc.type === "HISTORY PUSH") {
            history.push(res.beforeFunc.value);
          }
        }
        if (res.status === "success") {
          if (res.data.statuscode === 1006) {
            history.push("set2FA", {
              twoFA: res?.data?.twoFA,
              username: inputData.username,
            });
            return;
          }
          console.log("user profile match");
          history.push("twoFA", { loginData: inputData });
        } else {
          if (res.errorMsg !== "") {
            FloatingNotifications("error", res.errorMsg, 1500);
            setMisMatchText(res.errorMsg);

            setShowMismatch(true);
          }
        }
      } catch (error) {
        console.log(error);
        // setMisMatchText(error);
        setShowMismatch(true);
      }
    }
  };
  const forgotPasswordClick = () => {
    history.push("resetPassword");
  };
  return (
    <div className="login_module">
      <div className="login_left">
        <div className="login_Container">
          <div className="login_Container1">
            <TitleElement />
            <form className="mainBlock">
              <div className="signInTitle">Sign In</div>
              <div className="signInContent">Sign in to stay connected.</div>
              <LoginInputTextboxField
                ref={resetRefs["username"]}
                title="User Name"
                validation={isNotEmpty}
                error_message={error_message["username"]}
                storeData={storeData.bind(null, "username")}
                superText=""
                autoComplete="off"
                autoFocus={true}
              />
              <LoginInputTextboxField
                ref={resetRefs["password"]}
                title="Password"
                type="password"
                validation={isNotEmpty}
                error_message={error_message["password"]}
                storeData={storeData.bind(null, "password")}
                superText=""
                autoComplete="new-password"
              />
              <div className="forgotPasswordSection">
                <div className="rememberME">Remember me?</div>
                <div className="forgotPassword" onClick={forgotPasswordClick}>
                  Forgot Password
                </div>
              </div>
              <Button
                divClName="signInButton"
                buttonClName="signIn"
                onClick={submitClickHandler}
                BtnTxt="Sign In"
              />
              {showMismatch && <div className="misMatch">{misMatchText}</div>}
              <div className="newAccount">
                <div className="content">Don’t have an account?&nbsp;</div>
                <div className="link">Click here to sign up.</div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="login_right">
        {/* <img src={LoginImage} alt="" /> */}
        <div>
          {!isLoaded && isLoadStarted && (
            <Blurhash
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                margin: "0",
              }}
              hash={
                "|51#jXZxQkkDkYaHaHf.j?f9e+e,fle,kZf,e-flR2kupMa#bJe,kFflf5g7f.f:f6kEabadflj^e,aHf,e,kEkZacaxf4gSf-f-fmaHflkDf,f5g6fnj]f-abade,f5f,f.f5e+f-j?f5fkacfjflf4e*f5fjf5f-flac"
              }
              resolutionX={32}
              resolutionY={32}
              punch={0}
            />
          )}
          <LazyLoadImage
            style={{
              height: "100%",
              width: "100%",
            }}
            src={LoginImage}
            // effect="blur"
            onLoad={handleLoad}
            beforeLoad={handleLoadStarted}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;

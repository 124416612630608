import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Blurhash } from "react-blurhash";

import LoginImage from "../images/login_module/LoginGraphic.svg";
import useImageLoad from "../hooks/use-imageLoad";

import TitleElement from "../loginModuleComponents/TitleElement";
import Button from "../loginModuleComponents/Button";
import Success from "../images/resetPasswordSuccess_page/success.png";

import "../scss/login_module.scss";
import "../scss/resetPasswordSuccess.scss";

const ResetPasswordSuccess = () => {
  let history = useHistory();
  const { handleLoad, handleLoadStarted, isLoaded, isLoadStarted } =
    useImageLoad();
  const continueClickHandler = (event) => {
    event.preventDefault();
    history.push("login");
  };

  return (
    <div className="login_module">
      <div className="login_left">
        <div className="resetPasswordSuccess">
          <div className="resetPasswordSuccess1">
            <TitleElement />
            <form className="mainBlock">
              <div className="imgContainter">
                <img src={Success} alt="" />
              </div>
              <div className="title">Success !</div>
              <div className="desc">Password update successfully</div>
              <Button
                divClName="continueButton"
                buttonClName="continue"
                onClick={continueClickHandler}
                BtnTxt="Continue"
              />
            </form>
          </div>
        </div>
      </div>
      <div className="login_right">
        <div>
          {!isLoaded && isLoadStarted && (
            <Blurhash
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                margin: "0",
              }}
              hash={
                "|51#jXZxQkkDkYaHaHf.j?f9e+e,fle,kZf,e-flR2kupMa#bJe,kFflf5g7f.f:f6kEabadflj^e,aHf,e,kEkZacaxf4gSf-f-fmaHflkDf,f5g6fnj]f-abade,f5f,f.f5e+f-j?f5fkacfjflf4e*f5fjf5f-flac"
              }
              resolutionX={32}
              resolutionY={32}
              punch={0}
            />
          )}
          <LazyLoadImage
            style={{
              height: "100%",
              width: "100%",
            }}
            src={LoginImage}
            // effect="blur"
            onLoad={handleLoad}
            beforeLoad={handleLoadStarted}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;

import "../scss/maintenance.scss";
import Error404 from "../images/maintenance/Error 404.svg";
const Maintenance = () => {
  return (
    <div className="Maintenancepage">
      <img src={Error404} alt="" />
    </div>
  );
};

export default Maintenance;

import React, { Suspense } from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";
import "../scss/defaultContainer.scss";
// import PatientRegistration from "../pages/PatientRegistration";
// import Orders from "../pages/Orders";
// import KitOrderAddition from "../pages/KitOrderAddition";
// import CollectionPage from "../pages/CollectionPage";
// import Resulted from "./../pages/Resulted";
// import LabLoginMgmt from "../pages/LabLoginMgmt";
// import OrgCreation from "../pages/OrgCreation";
// import OrgDetails from "../pages/OrgDetails";
// import SubOrgDetails from "../pages/SubOrgDetails";
// import KitCreation from "../pages/KitCreation";
// import TestCreation from "../pages/TestCreation";
// import PanelCreation from "../pages/PanelCreation";
// import Maintenance from "../pages/Maintenance";
// import NavBar from "./NavBar";
// import TopBar from "./TopBar";

const PatientRegistration = React.lazy(() =>
  import("../pages/PatientRegistration")
);
const Orders = React.lazy(() => import("../pages/Orders"));
const CollectionPage = React.lazy(() => import("../pages/CollectionPage"));
const ReceivedPage = React.lazy(() => import("../pages/ReceivedPage"));
const LabLoginMgmt = React.lazy(() => import("../pages/LabLoginMgmt"));
const OrgCreation = React.lazy(() => import("../pages/OrgCreation"));
const OrgDetails = React.lazy(() => import("../pages/OrgDetails"));
const SubOrgDetails = React.lazy(() => import("../pages/SubOrgDetails"));
const KitCreation = React.lazy(() => import("../pages/KitCreation"));
const TestCreation = React.lazy(() => import("../pages/TestCreation"));
const PanelCreation = React.lazy(() => import("../pages/PanelCreation"));
const OrderStatus = React.lazy(() => import("../pages/OrderStatus"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const ProfileDetails = React.lazy(() => import("../pages/ProfileDetails"));
const Maintenance = React.lazy(() => import("../pages/Maintenance"));
const NavBar = React.lazy(() => import("./NavBar"));
const TopBar = React.lazy(() => import("./TopBar"));

const DefaultContainer = () => {
  const { accessType } = useParams();
  const userName = localStorage.getItem("marinaUser");
  const userType = localStorage.getItem("marinaUserType");
  const loginType = localStorage.getItem("marinaLoginType");
  console.log(accessType);

  return (
    <div className="wholeContainer">
      <div className="navContainer">
        <Suspense fallback={<div>Loading...</div>}>
          <NavBar accessType={accessType} />
        </Suspense>
      </div>
      <Switch>
        <Suspense fallback={<div>Loading...</div>}>
          <div className="mainContainer">
            <TopBar
              userName={userName}
              userType={userType}
              loginType={loginType}
            />
            <Route path="/:accessType" exact>
              <Redirect to={`/${accessType}/dashboard`} />
            </Route>
            <Route path="/:accessType/patientRegistration" exact>
              <PatientRegistration />
            </Route>
            <Route path="/:accessType/orders" exact>
              <Orders />
            </Route>
            <Route path="/:accessType/orderStatus" exact>
              <OrderStatus />
            </Route>
            {/* <Route path="/:accessType/kitOrderAddition" exact>
              <KitOrderAddition />
            </Route> */}
            <Route path="/:accessType/collection" exact>
              <CollectionPage />
            </Route>
            <Route path="/:accessType/received" exact>
              <ReceivedPage />
            </Route>
            <Route path="/:accessType/labLoginMgmt" exact>
              <LabLoginMgmt />
            </Route>
            <Route path="/:accessType/orgCreation" exact>
              <OrgCreation />
            </Route>
            <Route path="/:accessType/orgDetails" exact>
              <OrgDetails />
            </Route>
            <Route path="/:accessType/subOrgDetails" exact>
              <SubOrgDetails />
            </Route>
            <Route path="/:accessType/kitCreation" exact>
              <KitCreation />
            </Route>
            <Route path="/:accessType/testCreation" exact>
              <TestCreation />
            </Route>
            <Route path="/:accessType/panelCreation" exact>
              <PanelCreation />
            </Route>
            <Route path="/:accessType/dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/:accessType/profileDetails" exact>
              <ProfileDetails />
            </Route>
            <Route path="/:accessType/maintenance" exact>
              <Maintenance />
            </Route>
          </div>
        </Suspense>
      </Switch>
    </div>
  );
};

export default DefaultContainer;

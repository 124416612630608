const Button = ({ divClName, buttonClName, onClick, BtnTxt }) => {
  return (
    <div className={divClName}>
      <button
        className={buttonClName}
        type="submit"
        value="submit"
        onClick={onClick}
      >
        {BtnTxt}
      </button>
    </div>
  );
};

export default Button;

import { useRef, useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Blurhash } from "react-blurhash";

import LoginImage from "../images/login_module/LoginGraphic.svg";
import useImageLoad from "../hooks/use-imageLoad";
import LoginInputTextboxField from "../components/to_use_hooks/LoginInputTextboxField";
import Button from "../loginModuleComponents/Button";
import TitleElement from "../loginModuleComponents/TitleElement";
import { isNotEmpty } from "../utilities/validationFunction";
import "../scss/login_module.scss";
import "../scss/resetLink.scss";

import { ApiHandler } from "../api/ApiHandler";
import FloatingNotifications from "../components/FloatingNotifications";

const ResetLink = () => {
  let history = useHistory();
  const location = useLocation();
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState("");
  const [showMismatch, setShowMismatch] = useState(false);
  const { handleLoad, handleLoadStarted, isLoaded, isLoadStarted } =
    useImageLoad();
  const storeData = useCallback((fieldName, value, validityStatus) => {
    console.log(fieldName, value, validityStatus);
    setInputData((prevState) => {
      // console.log(prevState);
      return { ...prevState, [fieldName]: value };
    });
    if (inputDataValidity.hasOwnProperty(fieldName)) {
      setInputDataValidity((prevState) => ({
        ...prevState,
        [fieldName]: validityStatus,
      }));
    }
  }, []);

  const error_message = {
    enterOtp: "This field should not be empty",
    newPassword: "This field should not be empty",
    reEnterNewPassword: "This field should not be empty",
  };

  const resetRefs = {
    enterOtp: useRef(null),
    newPassword: useRef(null),
    reEnterNewPassword: useRef(null),
  };

  const [inputData, setInputData] = useState({
    enterOtp: "",
    newPassword: "",
    reEnterNewPassword: "",
  });

  const [inputDataValidity, setInputDataValidity] = useState({
    enterOtp: false,
    newPassword: false,
    reEnterNewPassword: false,
  });

  useEffect(() => {
    var temp = true;
    Object.values(inputDataValidity).forEach((value) => {
      temp = temp && value;
    });
    setValid(temp);
    if (showMismatch) {
      setShowMismatch(false);
    }
  }, [inputDataValidity]);

  const updateClickHandler = async (event) => {
    event.preventDefault();
    Object.values(resetRefs).forEach((value) => {
      value.current.submitClick();
    });
    console.log("reset link button clicked", inputData);
    if (valid) {
      if (inputData.newPassword === inputData.reEnterNewPassword) {
        const res = await ApiHandler({
          requestConfig: {
            method: "post",
            endPoint: "resetPassword",
            data: {
              email,
              otp: inputData.enterOtp,
              newPassword: inputData.newPassword,
            },
          },
        });

        console.log(res);
        if (res.hasOwnProperty("beforeFunc")) {
          if (res.beforeFunc.type === "HISTORY PUSH") {
            history.push(res.beforeFunc.value);
          }
        }

        if (res.status === "success") {
          const { data } = res;
          console.log(data);
          history.push("resetPasswordSuccess");
        } else {
          if (res.errorMsg !== "") {
            FloatingNotifications("error", res.errorMsg, 1500);
          }
        }
      } else {
        setShowMismatch(true);
      }
    }
  };

  useEffect(() => {
    if (location.state ?? false) {
      setEmail(location.state?.email ?? "");
      console.log(
        "Reset link page received email = ",
        location.state?.email ?? ""
      );
    }
  }, [location.state]);

  return (
    <div className="login_module">
      <div className="login_left">
        <div className="resetLink">
          <div className="resetLink1">
            <TitleElement />
            <form className="mainBlock">
              <div className="title">Reset Password</div>
              <div className="desc">Enter New password</div>
              <LoginInputTextboxField
                ref={resetRefs["enterOtp"]}
                title="Enter OTP"
                validation={isNotEmpty}
                error_message={error_message["enterOtp"]}
                storeData={storeData.bind(null, "enterOtp")}
                superText=""
                autoComplete="new-password"
              />
              <LoginInputTextboxField
                ref={resetRefs["newPassword"]}
                title="New Password"
                type="password"
                validation={isNotEmpty}
                error_message={error_message["newPassword"]}
                storeData={storeData.bind(null, "newPassword")}
                superText=""
                autoComplete="new-password"
              />
              <LoginInputTextboxField
                ref={resetRefs["reEnterNewPassword"]}
                title="Reenter New Password"
                type="password"
                validation={isNotEmpty}
                error_message={error_message["reEnterNewPassword"]}
                storeData={storeData.bind(null, "reEnterNewPassword")}
                superText=""
                autoComplete="new-password"
              />

              <Button
                divClName="updateButton"
                buttonClName="update"
                onClick={updateClickHandler}
                BtnTxt="Update"
              />
              {showMismatch && (
                <div className="misMatch">Passwords doesn't match</div>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="login_right">
        <div>
          {!isLoaded && isLoadStarted && (
            <Blurhash
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                margin: "0",
              }}
              hash={
                "|51#jXZxQkkDkYaHaHf.j?f9e+e,fle,kZf,e-flR2kupMa#bJe,kFflf5g7f.f:f6kEabadflj^e,aHf,e,kEkZacaxf4gSf-f-fmaHflkDf,f5g6fnj]f-abade,f5f,f.f5e+f-j?f5fkacfjflf4e*f5fjf5f-flac"
              }
              resolutionX={32}
              resolutionY={32}
              punch={0}
            />
          )}
          <LazyLoadImage
            style={{
              height: "100%",
              width: "100%",
            }}
            src={LoginImage}
            // effect="blur"
            onLoad={handleLoad}
            beforeLoad={handleLoadStarted}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetLink;

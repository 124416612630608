import { ApiHandler } from "../api/ApiHandler";
import FloatingNotifications from "../components/FloatingNotifications";

export const initialState = {
  page: "",
  valueList: [],
  selValue: {},
  searchInput: "",
  errorData: {},
  functionResult: (value) => {
    return { changeOfInput: [value, []], receiveSuccess: false };
  },
  getOptionLabel: (option) => {
    return "";
  },
};
export const storeReducer = (state = initialState, action) => {
  // const history = useHistory();
  const getDataFromAPI = async (value) => {
    console.log(value);
    const searchInput = value;
    let patientList = [];
    console.log("Options Fetched from API");
    if (value === "") return [searchInput, patientList];
    const res = await ApiHandler({
      requestConfig: {
        method: "get",
        endPoint: "getPatientListBySearch",
        data: {
          searchKey: searchInput,
          patientBelowId: localStorage.getItem("marinaOrgId"),
        },
      },
    });

    let receiveSuccess;
    console.log(res);
    if (res.status === "success") {
      const _data = res.data;
      console.log(_data);
      patientList = _data;
      receiveSuccess = true;
    } else {
      receiveSuccess = false;

      if (res.errorMsg !== "") {
        FloatingNotifications("error", res.errorMsg, 1500);
      }
    }
    return {
      changeOfInput: [searchInput, patientList],
      receiveSuccess,
      response: res,
    };
  };

  // console.log("In Store Reducer ", state, action);
  if (action.type === "SET PAGE") {
    if (action.page === "patientRegisterationPage") {
      return {
        ...state,
        page: action.page,
        getOptionLabel: (option) =>
          [
            String(option.patientId),
            option.firstName,
            option.lastName,
            option.phNo,
          ]
            .filter((item) => item !== "")
            .join(" "),
        functionResult: getDataFromAPI,
      };
    } else {
      return { ...state, page: action.page };
    }
  } else if (action.type === "SET VALUE") {
    return { ...state, searchInput: action.searchInput };
  } else if (action.type === "SET VALUELIST") {
    return { ...state, valueList: action.valueList };
  } else if (action.type === "SET SELVALUE") {
    return { ...state, selValue: action.selValue };
  } else if (action.type === "SET ERROR DATA") {
    return { ...state, errorData: action.errorData };
  } else if (action.type === "CLEAR ERROR DATA") {
    return { ...state, errorData: {} };
  } else if (action.type === "RESET REG PAGE VALUES") {
    return { ...state, valueList: [], selValue: null, searchInput: "" };
  }
  return state;
};
